<template>
  <div>
    <div class="row">
      <div class="card-body">
        <fieldset :disabled="disabled">
        <form class="needs-validation" @submit.prevent="formSubmit">
          <div class="row">
            <div class="col-md-6 champ">
              <div class="form-group">
                <label for="formrow-email-input">Secteur d'activités *</label>
                <input
                  id="projectname"
                  v-model="regularForm.supliersecteurAct"
                  type="text"
                  class="form-control"
                
                  :class="{
                    'is-invalid': submitted && $v.regularForm.supliersecteurAct.$error,
                  }"
                  placeholder=""
                />
                <div v-if="submitted && $v.regularForm.supliersecteurAct.$error" class="invalid-feedback">
                  <span v-if="!$v.regularForm.supliersecteurAct.required">La champ est obligatoire.</span>
                </div>
              </div>
            </div>
             <div class="col-md-6 champ">
              <div class="form-group">
                <label for="formrow-email-input">Code d'activités </label>
                <input
                  id="projectname"
                  v-model="regularForm.suplierCodeAct"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierCodeAct.$error,
                  }"
                  placeholder=""
                />
                <div
                  v-if="submitted && $v.regularForm.suplierCodeAct.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierCodeAct.required"
                    >La champ est obligatoire.</span
                  >

                </div>
              </div>
            </div>
          </div>
          <div class="row">
          
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Adresse Siège sociale *</label>
                <input
                  id="projectname"
                  v-model="regularForm.suplierAdress1"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierAdress1.$error,
                  }"
                  placeholder=""
                />
                <div
                  v-if="submitted && $v.regularForm.suplierAdress1.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierAdress1.required"
                    >Le champ est obligatoire.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Adresse Commerciale *</label>
                <input
                  id="projectname"
                  v-model="regularForm.suplierAdress2"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierAdress2.$error,
                  }"
                  placeholder=""
                />
                <div
                  v-if="submitted && $v.regularForm.suplierAdress2.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierAdress2.required"
                    >Le champ est obligatoire.</span
                  >

                </div>
              </div>
            </div>
             <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Adresse Commerciale 2 </label>
                <input
                  id="projectname"
                  v-model="regularForm.suplierAdress3"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierAdress3.$error,
                  }"
                  placeholder=""
                />
                <div
                  v-if="submitted && $v.regularForm.suplierAdress3.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierAdress3.required"
                    >Le champ est obligatoire.</span
                  >

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">NIF *</label>
                <input
                  id="projectname"


                  v-mask="'###############'"
                  v-model="regularForm.suplierNif"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierNif.$error,
                  }"
                  placeholder="---------------"
                />
                <div
                  v-if="submitted && $v.regularForm.suplierNif.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierNif.required"
                    >Le champ est obligatoire.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">NIS *</label>
                <input
                  id="projectname"
                  v-mask="'# ### #### #####  ##'"
                  v-model="regularForm.suplierNic"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierNic.$error,
                  }"
                  placeholder="- --- ---- ----- -- "
                />
                 <div
                  v-if="submitted && $v.regularForm.suplierNic.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierNic.required"
                    >Le champ  est obligatoire.</span
                  >

                </div>
              </div>
            </div>
             <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">N° RC  *</label>

                <input
                  id="projectname"
                  v-model="regularForm.suplierRcommerce"
                  v-mask="'## / ## ###### X##'"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.regularForm.suplierRcommerce.$error,
                  }"
                  placeholder="-- / -- ------ A–-"


                />
                <div
                  v-if="submitted && $v.regularForm.suplierRcommerce.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.regularForm.suplierRcommerce.required"
                    >Le champ est obligatoire.</span
                  >
              </div>
            </div>
            </div>
            </div>
          <div class="row">
            <div class="col-md-12" v-show="!disabled"> 
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button class="btn btn-label btn-primary float-right btn-label" type="submit" v-show="disabledAdd || disabledUpdate">
                    <i class="far fa-save label-icon "></i> Enregistrer
                </button>
            </div>
          </div>
        </form>
       </fieldset>
        
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
} from "vuelidate/lib/validators";
  import Swal from "sweetalert2";
export default {
  props:[
    'uuid',
    'supplier',
    'GeneralData',
    'selectedSupplier',
    'addBtnProps' ,'editBtnProps' ,'deleteBtnProps','resetBtnProps','clearGenInfo'
  ],


  data() {
    return {
      props:['uuid'],
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      regularForm: {
        // Regular Info
        supliersecteurAct: "",
        suplierAdress1: "",
        suplierAdress2: "",
        suplierAdress3: "",
        suplierNif:"",
        suplierRcommerce:"",
        suplierNic:"",
        suplierCodeAct: "",
         uuid:"",
        suplierPrestname: "",
        suplierEmail:"",
        suplierPhone: "",
        suplierRaisonSocial: "",
        suplierCapitalSocial: "",
        suplierStatutEnt: "",
        suplierNomMaison: "",
        suplierEmailMaison: "",
        suplierPhoneMaison:"",
        suplierAdressMaison:"",
        suplier_is_externe:"",
        code:"",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : true,
    };
  },

  validations: {
    regularForm: {
        supliersecteurAct: { required },
        suplierAdress1: { required },
        suplierAdress2: { required },
        suplierAdress3: { required },
        suplierCodeAct: { required },
        suplierNif:{required},
        suplierNic:{required},
        suplierRcommerce:{required},
      
    },
  },
  watch:{
    clearGenInfo(){
      if(this.clearGenInfo) this.clearForm();
    },
    resetBtnProps(){
      if(this.resetBtnProps)
      this.cancelBtn();
    },
    addBtnProps:{
      handler(val){
        if(val)
        this.addBtn();
      }
    },
    editBtnProps:{
      handler(val){
        if(val)
        this.editBtn();
      }
    },
    deleteBtnProps:{
      handler(val){
        if(val)
        this.deleteBtn();
      }
    },
    selectedSupplier(){
      
      if(this.selectedSupplier.length != 0){
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledUpdate = false;
        this.regularForm.id                = this.selectedSupplier.id;
        this.regularForm.uuid              = this.selectedSupplier.uuid;
        this.regularForm.supliersecteurAct = this.selectedSupplier.secteurAct;
        this.regularForm.suplierAdress1    = this.selectedSupplier.adress1;
        this.regularForm.suplierAdress2    = this.selectedSupplier.adress2;
        this.regularForm.suplierAdress3    = this.selectedSupplier.adress3; 
        this.regularForm.suplierCodeAct    = this.selectedSupplier.code_activite;
        this.regularForm.suplierNif        = this.selectedSupplier.Nif;
        this.regularForm.suplierNic        = this.selectedSupplier.Nic;
        this.regularForm.suplierRcommerce  = this.selectedSupplier.Rcommerce;    
      }
      else{
        this.disabledAdd    = false;
        this.disabledUpdate = true;
        this.clearForm();

      }
    },
    GeneralData(){
       if(this.GeneralData){
        this.disabledUpdate    = true;
        this.disabled       = false;

        this.regularForm.uuid                 = this.GeneralData.uuid;
        this.regularForm.suplierPrestname     = this.GeneralData.suplierPrestname;
        this.regularForm.suplierEmail         = this.GeneralData.suplierEmail;
        this.regularForm.suplierPhone         = this.GeneralData.suplierPhone;
        this.regularForm.suplierRaisonSocial  = this.GeneralData.suplierRaisonSocial; 
        this.regularForm.suplierCapitalSocial = this.GeneralData.suplierCapitalSocial;
        this.regularForm.suplierStatutEnt     = this.GeneralData.suplierStatutEnt;
        this.regularForm.suplierNomMaison     = this.GeneralData.suplierNomMaison;
        this.regularForm.suplierEmailMaison   = this.GeneralData.suplierEmailMaison;
        this.regularForm.suplierPhoneMaison   = this.GeneralData.suplierPhoneMaison;
        this.regularForm.suplierAdressMaison  = this.GeneralData.suplierAdressMaison;     
        this.regularForm.suplier_is_externe   = this.GeneralData.suplier_is_externe;
        this.regularForm.code                 = this.GeneralData.code;

      } 
    }
  },
  
  methods:{
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.clearForm();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
    },
    
    deleteBtn(){
      if(this.regularForm.uuid != null)
      this.$emit('deleteSupp',{name : this.regularForm.suplierPrestname ,uuid : this.regularForm.uuid})
      else
     Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.clearForm();
      this.submitted = false;
      this.$emit('resetBtn',true);
      this.$emit('regularCancelBtn',true);
      // this.$emit('resetBtn',false);
    },
    clearForm(){
        this.regularForm.supliersecteurAct = "";
        this.regularForm.suplierAdress1    = "";
        this.regularForm.suplierAdress2    = "";
        this.regularForm.suplierAdress3    = "";
        this.regularForm.suplierCodeAct    = ""; 
        this.regularForm.suplierNif        = "";
        this.regularForm.suplierNic        = "";
        this.regularForm.suplierRcommerce  = "";  
    },
    validateFirstEtape(){
      if(this.regularForm.suplierPrestname == "" || this.regularForm.suplierEmail == "" || this.regularForm.suplierPhone == "" || 
        this.regularForm.suplierRaisonSocial == "" || this.regularForm.suplierCapitalSocial == "" || this.regularForm.suplierStatutEnt == "" )
      {
        Swal.fire("Erreur!", "Vous devez terminer la première étape avant de terminer cette étape", "error");
        return false;
      }
      return true;
    },
    formSubmit() {

      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid && this.validateFirstEtape()) {
        this.submitted = false
        this.$http
        .post("/contracts/suppliers/store_general_info", this.regularForm)
        // .post("/contracts/suppliers/store_regular_info", this.regularForm)
        .then((res) => {
          var status = res.data.original.status;
          var id = res.data.original.id;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              // Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refrechTable', true);
              this.$emit('gotofirstStep', true  ); 
              this.$emit('resetBtn',true);
              this.cancelBtn();
              break;
            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
              this.$toast.success(error.message);
          // Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
  }
};
</script>
