<script>
import {
  required,email , maxLength,minLength,numeric
  } from "vuelidate/lib/validators";
  import Swal from "sweetalert2";
  
import { getDropDownElementsByTypeApi } from "@/api/common";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
export default {
  import : { getDropDownElementsByTypeApi },
   props:['supID','selectedSupplier'],
  components: { Dataset,DatasetItem,/* DatasetInfo,*/DatasetPager,/* DatasetSearch,*/DatasetShow,},
  data() {
    return {
      title: "Supplier",
      data:undefined,
      List_Staff:[],
      selectedStaff:null,
      rows:'',
      spid : '',
      refreshStaffList :undefined,
      refreshTable:false,
      active:false,
      selectedRows:[],
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      suplierID:undefined,
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      posts:[],
       fields:
         {
        suplierRespFName: "",
        suplierRespLName: "",
        suplierRespFunction: "",
        suplierRespPost: "",
        suplierRespEmail: "",

        suplierRespPhone: "",
        },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : false,
      cols: [
        {
          name: "Nom ",
          field: "first_name",
          sort: ''
        },
        {
          name: "Prénom",
          field: "last_name",
          sort: ''
        },
        {
          name: "Email",
          field: "phone",
          sort: ''
        },
        {
          name: "Télèphone",
          field: "email",
          sort: ''
        },
        {
          name: "Adresse",
          field: "postTitle",
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },

  mounted(){
    this.getPost();
  },
 watch:{
  
   refreshStaffList:{
      handler(val){
        this.getStaff(this.selectedSupplier.id);
        this.refreshStaffList=false;
        
      }
    },
    
   selectedRows(){
     
        if(this.selectedRows.length == 1){
          
         this.selectedStaff              = this.selectedRows[0];

         this.fields.supplier_id         = this.selectedStaff.suplier_id;
         this.fields.uuid                = this.selectedStaff.uuid;
         this.fields.suplierRespFName    = this.selectedStaff.first_name;
         this.fields.suplierRespLName    = this.selectedStaff.last_name;
         this.fields.suplierRespPhone    = this.selectedStaff.phone;
         this.fields.suplierRespEmail    = this.selectedStaff.email;
         this.fields.suplierRespPost     = this.selectedStaff.post;
        }
        else{
         this.fields.supplier_id = "";
         this.fields.uuid = "";
         this.fields.suplierRespFName = "";
         this.fields.suplierRespLName = "";
         this.fields.suplierRespPhone = "";
         this.fields.suplierRespEmail = "";
         this.fields.suplierRespFunction = "";
         this.fields.suplierRespPost = "";
        }
   },

   selectedSupplier(){
     this.clearForm();
      if(this.selectedSupplier){
         var supplier_id = this.selectedSupplier.id;
         this.getStaff(supplier_id);
      }
      else{
        this.selectedSupplier = [];
        this.staffs = [];
      }
   },
   supID(){
     this.spid = this.supID
   }


  },
methods:
   {
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.clearForm();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
    },
     getPost(){
         getDropDownElementsByTypeApi('employee_function')

          .then((res) => {
              this.posts = res.data.original.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
    
     getStaff(supplier_id) {
     // let loader = this.$loading.show();
     if(supplier_id){
       let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
        this.$http.post("/contracts/suppliers/getSingleStaffData/" + supplier_id)
          .then((res) => {
            var status = res.data.original.status;
            var staffs= res.data.original.data.staffs;
            if(staffs){
              this.List_Staff = staffs;
            }else{
              this.List_Staff = [];
            }
            loader.hide();
            switch (status) {
              case 200:
                 
  
              break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
              break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
     }
       },
        clearForm(){
         this.fields.supplier_id = "";
         this.fields.uuid = "";
         this.fields.suplierRespFName = "";
         this.fields.suplierRespLName = "";
         this.fields.suplierRespPhone = "";
         this.fields.suplierRespEmail = "";
         this.fields.suplierRespFunction = "";
         this.fields.suplierRespPost = "";

        },
     formSubmit() {
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitted = false
        if(this.id){
         this.suplierID = this.id.id;
        }else {
          this.suplierID = this.selectedSupplier.id;
        } 
        if(this.spid != ''){
          this.suplierID = this.spid;
        }
        
        if(this.suplierID == undefined){
          Swal.fire("Avertissement!", "Tu dois choisir un Prestataire", "warning");
        }else{
          this.$http
          .post("/contracts/suppliers/store_staff_info", {staff : this.fields,suplierID : this.suplierID})
          .then((res) => {
            var status = res.data.original.status;
  
            switch (status) {
              case 200:
                Swal.fire("Félicitations!", res.data.original.msg, "success");
                // this.$emit('refrechTable', true);
                this.clearForm();
                this.refreshStaffList = true ;
                break;
              case 500:
                Swal.fire("Avertissement!", res.data.original.msg, "warning");
                break;
            }
          })
          .catch((error) => {
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
        }
        
      }
      },
      deleteStaff(){
        var name = this.fields.suplierRespFName +" "+this.fields.suplierRespLName
        if(this.fields.uuid == "" || this.fields.uuid == null)
        { 
          Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
        }else
          Swal.fire({
            title: "Êtes-vous sûr de supprimer :  " + name + "?",
            text: "Vous ne pourrez pas annuler cela!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Supprimer!",
          }).then((result) => {  
            if (result.value) {
              let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
              })
              this.$http
                .post("/contracts/suppliers/delete_staff" ,{ uuid :this.fields.uuid})
                .then((response) => {
                  var data = response.data;
                  if(data.errors != 0){
                    Swal.fire("Erreur!", data.msg, "error")
                  }else{
                    Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                    this.getStaff(this.fields.supplier_id);
                    this.clearForm()
                  }
                  loader.hide();
                  })
                  .catch((error) => {
                    loader.hide();
                    Swal.fire("Erreur!", error, "error")
                })
                .finally(() => {});
            }
          });
      }
        

   },
  validations: {
    
    fields: {
      suplierRespFName: { required , maxLength:maxLength(50),minLength:minLength(3)},
      suplierRespLName: { required ,maxLength:maxLength(50),minLength:minLength(3)},
      suplierRespEmail: { required ,email },
      suplierRespPhone: { required, numeric, maxLength:maxLength(10),minLength:minLength(9) }, 
      suplierRespPost: { required },     

    }
  },

};
</script>
<style>
  #StickySSI {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteSSI #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printSSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>
<template>
  <div>
         
    <div class="row">
      <div class="card-body">
         <!-- <h4 class="card-title">Liste des Staffs</h4> -->
          <div class="table-responsive mb-0 shadow">
             <dataset v-slot="{ ds }" :ds-data="List_Staff" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row }">
                              <tr  style="cursor:pointer">
                                <td>{{ row.last_name }}</td>
                                <td>{{ row.first_name }} </td>
                                <td>{{ row.phone }}</td>
                                <td>{{ row.email }}</td>
                                <td>{{ row.postTitle }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    
                      <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
.red{
color:red;
font-size: 22px;
}
.champ{
  padding-right: -10px;
  margin-right: -10px;
}


</style>
