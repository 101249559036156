
<script>
import {
  required,email , maxLength,minLength,minValue,numeric,requiredIf
  } from "vuelidate/lib/validators";
import { emailFormatter} from "@/helpers/functions";

  import Swal from "sweetalert2";
export default {
 props:['supplier','selectedSupplier','clearGenInfo','firstStepDisabled','addbtnSecond' ,'addBtnProps' ,'editBtnProps' ,'deleteBtnProps', 'resetBtnProps'],
 
  data() {
    return {
      title: "Contrats",
      props:['uuid'],
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      MaisonM : false,
      globalForm: {
    
        //information generale
        uuid:"",
        suplierPrestname: "",
        suplierEmail:"",
        suplierPhone: "",
        suplierRaisonSocial: "",
        suplierCapitalSocial: "",
        suplierStatutEnt: "",
        suplierNomMaison: "",
        suplierEmailMaison: "",
        suplierPhoneMaison:"",
        suplierAdressMaison:"",
        suplier_is_externe:"",
        code : ""
        
      },
      statuts:[
        {text:"E.U.R.L.",value :0},
        {text:"Entreprise Individuelle",value :1},
        {text:"Micro-Entreprise",value :2},
        {text:"P. Physique",value :3},
        {text:"S.A.R.L.",value :4},
        {text:"S.N.C.",value :5},
        {text:"S.P.A.",value :6},
        {text:"S.P.A.S.",value :7},
        {text:"Société Anonyme",value :8},

      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : true,
    };
  },

  validations: {
    globalForm: {
        code: { required , minLength:maxLength(60)},
        suplierPrestname: { required , minLength:maxLength(60)},
        suplierEmail: { required , 
         email: (val) => email(emailFormatter(val)),
        },
        suplierPhone: { required , numeric, maxLength:maxLength(10),minLength:minLength(9)},
        suplierRaisonSocial: { required },
        suplierCapitalSocial: { required,numeric,minValue:minValue(0)},
        suplierStatutEnt: { required },
        suplierNomMaison: { required: requiredIf(function() {
              return this.MaisonM;
          })},
        suplierEmailMaison: { required: requiredIf(function() {
              return this.MaisonM;
          }), 
          email: (val) => email(emailFormatter(val)),
        },
        suplierPhoneMaison: { required: requiredIf(function() {
              return this.MaisonM;
          }), numeric,maxLength:maxLength(10),minLength:minLength(9)},
         suplierAdressMaison: { 
           required: requiredIf(function() {
              return this.MaisonM;
          }),maxLength:maxLength(120) },
       

    },
  },

  watch:{
    addBtnProps(){
      if(this.addBtnProps)
      this.addBtn();
    },
    editBtnProps(){
      if(this.editBtnProps)
      this.editBtn();
    },
    deleteBtnProps(){
      if(this.deleteBtnProps)
      this.deleteBtn();
    },
    resetBtnProps(){
      if(this.resetBtnProps)
      this.cancelBtn();
    },
    firstStepDisabled:{
      handler(val){
        if(val) this.cancelBtn()
        this.firstStepDisabled = false
      }
    },
    clearGenInfo(){
      if(this.clearGenInfo == true){
        this.globalForm.uuid = "";
        this.globalForm.suplierPrestname = "";
        this.globalForm.suplierEmail = "";
        this.globalForm.suplierPhone = "";
        this.globalForm.suplierRaisonSocial = ""; 
        this.globalForm.suplierCapitalSocial = "";
        this.globalForm.suplierStatutEnt = "";
        this.globalForm.suplierNomMaison = "";
        this.globalForm.suplierEmailMaison = "";
        this.globalForm.suplierPhoneMaison = "";
        this.globalForm.suplierAdressMaison = "";    
        this.globalForm.suplier_is_externe = ""; 
      }
    },
    
    selectedSupplier(){
      this.clearForm();
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      if(this.selectedSupplier.length != 0){
        this.disabledAdd    = false;
        this.disabledUpdate = false;
        this.disabled       = true;
        this.globalForm.code                 = this.selectedSupplier.code;
        this.globalForm.uuid                 = this.selectedSupplier.uuid;
        this.globalForm.suplierPrestname     = this.selectedSupplier.prestname;
        this.globalForm.suplierEmail         = this.selectedSupplier.email;
        this.globalForm.suplierPhone         = this.selectedSupplier.phone;
        this.globalForm.suplierRaisonSocial  = this.selectedSupplier.raison_social; 
        this.globalForm.suplierCapitalSocial = this.selectedSupplier.capital_social;
        this.globalForm.suplierStatutEnt     = this.selectedSupplier.statut_entreprise;
        this.globalForm.suplierNomMaison     = this.selectedSupplier.maisonMere;
        this.globalForm.suplierEmailMaison   = this.selectedSupplier.emailMaisonMere;
        this.globalForm.suplierPhoneMaison   = this.selectedSupplier.phoneMaisonMere;
        this.globalForm.suplierAdressMaison  = this.selectedSupplier.adresseMaisonMere;     
        this.globalForm.suplier_is_externe   = this.selectedSupplier.is_externe;
      }
    } 
  },
mounted(){
    this.globalForm.uuid = this.$route.params.uid;
  },
   
  methods:{
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.clearForm();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
    },
    deleteBtn(){
      if( this.globalForm.uuid != "")
      this.$emit('deleteSupp',{name : this.globalForm.suplierPrestname ,uuid : this.globalForm.uuid})
      else
      Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
    },
    cancelBtn(){
      this.clearForm();
      this.submitted = false;
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.$emit('resetBtn',true);
      this.$emit('resetBtnDone',false);
      // this.$emit('resetBtn',false);
    },
    clearForm(){
        this.globalForm.uuid                 = "";
        this.globalForm.code                 = "";
        this.globalForm.suplierPrestname     = "";
        this.globalForm.suplierEmail         = "";
        this.globalForm.suplierPhone         = "";
        this.globalForm.suplierRaisonSocial  = ""; 
        this.globalForm.suplierCapitalSocial = "";
        this.globalForm.suplierStatutEnt     = "";
        this.globalForm.suplierNomMaison     = "";
        this.globalForm.suplierEmailMaison   = "";
        this.globalForm.suplierPhoneMaison   = "";
        this.globalForm.suplierAdressMaison  = "";    
        this.globalForm.suplier_is_externe   = ""; 
    },

     formSubmit() {
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        this.submitted = false;
        this.$emit('globalForm', this.globalForm); 
        this.$emit('nextIndex', true  ); 
        // this.$emit('nextIndex', false  ); 
      }

    },
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="card-body">
        <!--<b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
          obligatoires sont marqués avec (*)
        </b-alert> -->
        <fieldset :disabled="disabled">
        <form class="needs-validation" @submit.prevent="formSubmit">
          <h4 class="card-title mb-4">Informations de fournisseur </h4>
          <div class="row">
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                <label for="formrow-email-input">Code prestataire *</label>
                <input id="projectname"
                  v-model="globalForm.code" type="text" class="form-control"
                  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.code.$error,
                  }" placeholder="" />
                <div
                  v-if="submitted && $v.globalForm.code.$error"
                  class="invalid-feedback">
                  <span v-if="!$v.globalForm.code.required"
                    >Le champ est obligatoire . </span>
                  <span v-if="!$v.globalForm.code.maxLength"
                    >La taille maximum du champs est:60 Caractères
                    .</span>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                <label for="formrow-email-input">Nom de prestataire *</label>
                <input id="projectname"
                  v-model="globalForm.suplierPrestname" type="text" class="form-control"
                  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierPrestname.$error,
                  }" placeholder="" />
                <div
                  v-if="submitted && $v.globalForm.suplierPrestname.$error"
                  class="invalid-feedback">
                  <span v-if="!$v.globalForm.suplierPrestname.required"
                    >Le champ est obligatoire . </span>
                  <span v-if="!$v.globalForm.suplierPrestname.maxLength"
                    >La taille maximum du champs est:60 Caractères
                    .</span>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                  <label for="formrow-email-input">Raison social *</label>
                  <input
                    id="projectname"
                    v-model="globalForm.suplierRaisonSocial"
                    type="text"
                    class="form-control"
                    
                    
                    :class="{
                      'is-invalid': submitted && $v.globalForm.suplierRaisonSocial.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.globalForm.suplierRaisonSocial.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.globalForm.suplierRaisonSocial.required"
                      >La champ est obligatoire.</span>
                    
                  </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                  <label for="formrow-email-input">Capital social *</label>
                  <input
                    id="projectname"
                    v-model="globalForm.suplierCapitalSocial"
                    type="number"
                    class="form-control" min="0"
                    
                    
                    :class="{
                      'is-invalid': submitted && $v.globalForm.suplierCapitalSocial.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.globalForm.suplierCapitalSocial.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.globalForm.suplierCapitalSocial.required"
                      >Le champ est obligatoire . </span>
                      <span v-if="!$v.globalForm.suplierCapitalSocial.numeric"
                      >Le capital social doit étre un nombre.</span>
                  </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                  <label for="formrow-email-input">Statut d'entreprise *</label>
                  <select v-model="globalForm.suplierStatutEnt"  class="form-control "
                  
                    :class="{
                      'is-invalid': submitted && $v.globalForm.suplierStatutEnt.$error,
                      'disabledMS': disabled
                    }">
                    <option v-for="statut in statuts" :key="statut.value" :value="statut.text"> {{statut.text}} </option>
                  </select>

                  <div v-if="submitted && $v.globalForm.suplierStatutEnt.$error"
                    class="invalid-feedback">
                    <span v-if="!$v.globalForm.suplierStatutEnt.required"
                      >Le champ est obligatoire.</span>
                  </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                <label for="formrow-email-input">Email *</label>
                <input id="projectname"
                  v-model="globalForm.suplierEmail" type="text" class="form-control"
                  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierEmail.$error,
                  }" placeholder=""
                />
                <div v-if="submitted && $v.globalForm.suplierEmail.$error"
                  class="invalid-feedback">
                    <span v-if="!$v.globalForm.suplierEmail.required">
                    Le champ est obligatoire . </span>
                  <span v-if="!$v.globalForm.suplierEmail.email">
                    entrer un email valide.</span>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-3 champ">
              <div class="form-group">
                <label for="formrow-email-input">Télèphone *</label>
                <input id="projectname" v-model="globalForm.suplierPhone" type="text"
                  class="form-control"  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierPhone.$error,
                  }"
                  placeholder=""
                />
                <div v-if="submitted && $v.globalForm.suplierPhone.$error"
                  class="invalid-feedback" >
                  <span v-if="!$v.globalForm.suplierPhone.required"
                    >Le champ est obligatoire . </span>
                    <span v-if="!$v.globalForm.suplierPhone.numeric"
                  >le Numréo de Télèphone doit étre un nombre . </span>
                  <span v-if="!$v.globalForm.suplierPhone.minLength"
                  >La taille minimum du champs est: 9 nombres . </span>
                  <span v-if="!$v.globalForm.suplierPhone.maxLength"
                  >La taille maximum du champs est: 10 nombre
                  .</span>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-2 mt-4">
              <b-form-checkbox
                  v-model="globalForm.suplier_is_externe"
                  switch  :value="1"
                  class="mb-3"
                  >Prestataire externe</b-form-checkbox
                >
            </div>
          </div>
            
          <!-- <h4 class="card-title mb-4">
          <label for="mmradio">Maison Mére </label>
              <input v-model="MaisonM" 
                placeholder="" :value="0"
                class="form-check-input ml-3"
                type="checkbox"
                id="mmradio"
              />
          </h4>  -->
          <div class="row">
              <div class="col-2">
                <label for="mmradio">Maison Mére </label>
              </div>
               <b-form-checkbox v-model="MaisonM" switch class="mb-1">
                      <label></label>
                </b-form-checkbox>
            
          </div>
          <div class="row" v-show="MaisonM">
            <div class="col-md-12 champ">
              <div class="form-group">
                <label for="formrow-email-input">Nom </label>
                <input id="projectname"
                  v-model="globalForm.suplierNomMaison"
                  type="text" class="form-control" placeholder=""
                />
              </div>
            </div>
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Email  </label>
                <input id="projectname"
                  v-model="globalForm.suplierEmailMaison" type="text" class="form-control"
                  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierEmailMaison.$error,
                  }" placeholder="" />
                <div v-if="submitted && $v.globalForm.suplierEmailMaison.$error"
                  class="invalid-feedback">
                  <span v-if="!$v.globalForm.suplierEmailMaison.required">
                      Le champ est obligatoire . </span>
                  <span v-if="!$v.globalForm.suplierEmailMaison.email">
                      entrer un email valide.</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Télèphone </label>
                <input id="projectname" v-model="globalForm.suplierPhoneMaison"
                  type="text" class="form-control" 
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierPhoneMaison.$error,
                  }" placeholder="" />
                <div
                  v-if="submitted && $v.globalForm.suplierPhoneMaison.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.globalForm.suplierPhoneMaison.required">
                    Le champ est obligatoire . </span>
                    <span v-if="!$v.globalForm.suplierPhoneMaison.numeric"
                    >le Numréo de Télèphone doit étre un nombre . </span>
                    
                    <span v-if="!$v.globalForm.suplierPhoneMaison.minLength"
                    >La taille minimum du champs est: 9 nombres . </span>
                    
                    <span v-if="!$v.globalForm.suplierPhoneMaison.maxLength"
                    >La taille maximum du champs est: 10 nombre . </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 champ">
              <div class="form-group">
                <label for="formrow-email-input">Adresse </label>
                <input
                  id="projectname"
                  v-model="globalForm.suplierAdressMaison"
                  type="text"
                  class="form-control"
                  
                  :class="{
                    'is-invalid': submitted && $v.globalForm.suplierAdressMaison.$error,
                  }"
                  placeholder=""
                />
                <div
                  v-if="submitted && $v.globalForm.suplierAdressMaison.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.globalForm.suplierAdressMaison.required"
                    >le champ est obligatoire.</span
                  >
                </div>
              </div>
            </div>
          </div>
        
          <div class="row">
            <div class="col-md-12" v-show="!disabled"> 
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button class="btn btn-label btn-success float-right btn-label" type="submit" v-show="disabledAdd || disabledUpdate">
                    <i class="fas  fa-arrow-right label-icon "></i> Suivant
                </button>
            </div>
          </div>
        </form>
        </fieldset>
      </div>
    </div>
  </div>
</template>
